
export default class MosaicSession {
  constructor() {
    this.samples = null;
    this.url = null;
    this.apiVersion =  '/apiv1';
    this.client_application_id = null;
    this.user = null;
    this.globalApp = null;
  }

  promiseInit(sampleId, source, projectId, geneSetId, experimentId, buildName ) {
    let self = this;
    self.api = source + self.apiVersion;

    return new Promise((resolve, reject) => {
      let geneSet = null;
      self.promiseGetCurrentUser()
        .then(function(data) {
          self.user = data;
        })
        .catch(function(error) {
          console.log(error);
          reject(error)
        })

      self.promiseGetClientApplication()
      .then(function() {
        if (geneSetId) {
          return self.promiseGetGeneSet(projectId, geneSetId)
        } else {
          return Promise.resolve(null);
        }
      })
      .then(function(data) {
        geneSet = data;

        self.promiseGetSample(projectId, sampleId, null).then(theSample => {
          // Let's get the proband info first
          self.promiseGetFileMapForSample(projectId, theSample, 'proband', experimentId)
          .then(data => {
            theSample.files = data.fileMap;
            let loadInfo = {
                        'buildName':   buildName,
                        'bedURL':      theSample.files['bed.gz'],
                        'bedIndexURL': theSample.files['bed.gz.tbi'],
                        'bigwigURL':   theSample.files['bw'],
                        'vcfURL':      theSample.files['vcf'],
                        'tbiURL':      theSample.files['tbi'],
                        'sampleName':  theSample.name}
            if (theSample.files.bam != null) {
              loadInfo.alignmentURL = theSample.files.bam;
              if (theSample.files.bai) {
                loadInfo.alignmentIndexURL = theSample.files.bai;
              }
            } else if (theSample.files.cram != null) {
              loadInfo.alignmentURL = theSample.files.cram;
              if (theSample.files.crai) {
                loadInfo.alignmentIndexURL = theSample.files.crai;
              }
            }

            resolve({'loadInfo': loadInfo,  'geneSet': geneSet, 'user': self.user});
          })
          .catch(error => {
            let errorMsg = error.responseText ? error.responseText : error.toString();
            console.log(errorMsg)
            reject(errorMsg);
          })
        })
      })
      .catch(function(error) {
        let errorMsg = error.responseText ? error.responseText : error.toString();
        console.log(errorMsg)
        reject(errorMsg)
      })

    })

  }

  getErrorMessage(error) {
    if (error.hasOwnProperty('responseJSON') && error.responseJSON.hasOwnProperty('message')) {
      return error.responseJSON.message;
    } else if (error.hasOwnProperty('responseText')) {
      return error.responseText;
    } else {
      return error.toString()
    }

  }


  hasVariantSets(modelInfos, rel='proband') {
    let proband = modelInfos.filter(function(mi) {
      return mi.relationship == rel;
    })
    if (proband && proband.length > 0) {
      let fileInfos = proband[0].txt;
      return fileInfos && fileInfos.length > 0
    } else {
      return false;
    }
  }

  promiseGetClientApplication() {
    let self = this;

    return new Promise(function(resolve, reject) {
      if(self.client_application_id){
        resolve();
      }
      else {
        //TODO: uncomment reject when launch for rnasplice.iobio is set up in Mosaic
        resolve();
        //reject("Cannot find Mosaic client_application for gene");
      }
    })
  }


  promiseGetProject(project_id) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getProject(project_id)
      .done(data => {
          resolve(data);
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error getting project " + project_id + ": " + errorMsg);
      });
    });
  }



  promiseGetSample(project_id, sample_id, rel) {
    let self = this;

    return new Promise(function(resolve, reject) {
      // Get pedigree for sample
      self.getSample(project_id, sample_id)
      .done(data => {
        if (rel) {
          let sample = {};
          sample[rel] = data;
          resolve(sample);
        } else {
          resolve(data);
        }
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error getting sample " + sample_id + ": " + errorMsg);
      })
    })
  }

  promiseGetPedigreeForSample(project_id, sample_id, isPedigree) {
    let self = this;

    return new Promise(function(resolve, reject) {
      if (isPedigree) {
        // If the user click 'Pedigree' from the Mosaic launch dialog,
        // get the pedigree for this sample. We will launch gene.iobio
        // for the proband of this pedigree, regardless of which sample
        // was selected. For example, the father could be selected, and
        // the pedigree will be located for the father, and we will launch
        // gene.iobio for the proband of that pedigree.
        self.getPedigreeForSample(project_id, sample_id)
        .done(rawPedigree => {
          const rawPedigreeOrig = $.extend({}, rawPedigree);
          let pedigree = self.parsePedigree(rawPedigree, sample_id)
          if (pedigree) {
            resolve({foundPedigree: true, pedigree: pedigree, rawPedigree: rawPedigreeOrig});
          }
          else {
            self.promiseGetSample(project_id, sample_id, 'proband')
            .then(function(data) {
              data.foundPedigree = false;
              resolve(data);
            })
          }
        })
        .fail(error => {
          let errorMsg = self.getErrorMessage(error);
          reject("Error getting pedigree for sample " + sample_id + ": " + errorMsg);
        })

      } else {
        // If the user clicked 'Individual' from the Mosaic launch dialog, we
        // will treat the selected sample as the proband.
        self.promiseGetSample(project_id, sample_id, 'proband')
        .then(function(data) {
          data.foundPedigree = false;
          resolve(data);
        })

      }
    })
  }

  parsePedigree(raw_pedigree, sample_id) {
    let self = this;

    // This assumes only 1 proband. If there are multiple affected samples then
    // the proband will be overwritten
    // This also assume no grandparents/grandchildren

    let pedigree = {}

    // Look for proband, which should have mother and father filled in and is the sample selected
    let probandIndex = raw_pedigree.findIndex(d => ( d.id == sample_id && d.pedigree.maternal_id && d.pedigree.paternal_id ) );
    // If the sample selected doesn't have a mother and father (isn't a proband), find
    // the proband by looking for a child with mother and father filled in and affected status
    if (probandIndex == -1) {
      probandIndex = raw_pedigree.findIndex(d => ( d.pedigree.affection_status == 2 && (d.pedigree.maternal_id || d.pedigree.paternal_id )) );
    }
    // If the sample selected doesn't have a mother and father (isn't a proband), find
    // the proband by looking for a child with mother and father filled in and unknown affected status
    if (probandIndex == -1) {
      probandIndex = raw_pedigree.findIndex(d => ( d.pedigree.affection_status == 0 && (d.pedigree.maternal_id || d.pedigree.paternal_id ) ));
    }

    if (probandIndex == -1) {
      // Assume proband if there is only one sample in the pedigree
      if (raw_pedigree.length == 1) {
        probandIndex = 0;
      } else {
        // Assume proband is the sample selected
        probandIndex = raw_pedigree.findIndex(d => (d.id == sample_id));
      }
    }


    if (probandIndex != -1) {
      // Proband
      const proband  = raw_pedigree.splice(probandIndex, 1)[0];
      pedigree['proband'] = proband;

      // Get mother
      const motherIndex = raw_pedigree.findIndex(d => d.id == proband.pedigree.maternal_id)
      if (motherIndex != -1) {
        pedigree['mother'] = raw_pedigree.splice(motherIndex, 1)[0]
        this.isMother = true;
      }

      // Get mother
      const fatherIndex = raw_pedigree.findIndex(d => d.id == proband.pedigree.paternal_id)
      if (fatherIndex != -1) {
        pedigree['father'] = raw_pedigree.splice(fatherIndex, 1)[0]
        this.isFather = true;
      }

      raw_pedigree.forEach(sample => {
        if (sample.pedigree.maternal_id != null || sample.pedigree.paternal_id != null
            && sample.pedigree.id != pedigree.proband.id) {
          pedigree['siblings'] = (pedigree['siblings'] || [] )
          pedigree['siblings'].push(sample);
        } else {
          pedigree['unparsed'] = (pedigree['siblings'] || []).push(sample)
        }
      })

      return pedigree;


    } else {
      return null;
    }

  }

  getPedigreeForSample(project_id, sample_id) {
    let self = this;
    return $.ajax({
      url: self.api + '/projects/' + project_id +  '/samples/' + sample_id + '/pedigree',
      type: 'GET',
      contentType: 'application/json',
      headers: {
        'Authorization': localStorage.getItem('hub-iobio-tkn')
      }
    });
  }


  getSample(project_id, sample_id) {
    let self = this;
    return $.ajax({
      url: self.api + '/projects/' + project_id + '/samples/' + sample_id,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        'Authorization': localStorage.getItem('hub-iobio-tkn')
      }
    });
  }


  promiseGetFileMapForSample(project_id, sample, relationship, experiment_id) {
    let self = this;
    return new Promise((resolve,reject) => {
      var promises = [];
      var fileMap = {};
      var currentSample = sample;
      self.promiseGetFilesForSample(project_id, currentSample.id)
      .then(files => {
        if (files) {
          files.filter(file => {
            if(experiment_id){
              return file.experiment_ids.includes(Number(experiment_id))
            }
            else {
              return file
            }
          }).filter(file => {
            return file.type
          })
          .forEach(file => {

            var p = self.promiseGetSignedUrlForFile(project_id, currentSample.id, file)
            .then(signed => {
              if (file.type == 'txt' || file.type == 'tsv') {
                var files = fileMap.txt;
                if (files == null) {
                  files = [];
                  fileMap.txt = files;
                }
                files.push({'url': signed.url, 'name': file.nickname});

              } else {
                fileMap[file.type] = signed.url
                if (file.type == 'vcf') {
                  if (file.vcf_sample_name == null || file.vcf_sample_name == "") {
                    reject("Missing vcf_sample_name for file " + file.name)
                  } else {
                    sample.vcf_sample_name = file.vcf_sample_name;
                  }
                }
              }
            })
            promises.push(p);
          })
          Promise.all(promises)
          .then(response => {
            resolve({'sample': sample, 'relationship': relationship, 'fileMap': fileMap});
          })
          .catch(errorMsg => {
            reject(errorMsg);
          })
        }

      })
    })
  }



  promiseGetFilesForSample(project_id, sample_id) {
    let self = this;
    return new Promise((resolve,reject) => {
      self.getFilesForSample(project_id, sample_id)
      .done(response => {
        resolve(response.data);
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Unable to get files for sample " + sample_id + " error: " + errorMsg)
        reject(errorMsg);
      })
    })
  }


  getFilesForSample(project_id, sample_id) {
    let self = this;
    return $.ajax({
      url: self.api + '/projects/' + project_id +  '/samples/' + sample_id + '/files',
      type: 'GET',
      contentType: 'application/json',
      headers: {
        'Authorization': localStorage.getItem('hub-iobio-tkn')
      }
    });
  }

  promiseGetFilesForProject(project_id) {
      let self = this;
      return new Promise((resolve,reject) => {
          self.getFilesForProject(project_id)
              .done(response => {
                  resolve(response);
              })
              .fail(error => {
                let errorMsg = self.getErrorMessage(error);
                console.log("Unable to get files for project " + project_id + " error: " + errorMsg);
                reject(errorMsg);
              })
      })
  }


  getFilesForProject(project_id) {
      let self = this;
      return $.ajax({
          url: self.api +  '/projects/' + project_id + '/files',
          type: 'GET',
          contentType: 'application/json',
          headers: {
              'Authorization': localStorage.getItem('hub-iobio-tkn')
          }
      });
  }

  promiseGetSignedUrlForFile(project_id, sample_id, file) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getSignedUrlForFile(project_id, sample_id, file)
      .done(file => {
        resolve(file);
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        let msg = "Could not get signed url for file_id  " + file.id + " error: " + errorMsg;
        console.log(msg)
        reject(msg);
      })
    })
  }

  getSignedUrlForFile (project_id, sample_id, file) {
    let self = this;
    return $.ajax({
      url: self.api +  '/projects/' + project_id + '/files/' + file.id + '/url',
      type: 'GET',
      contentType: 'application/json',
      headers: {
        'Authorization': localStorage.getItem('hub-iobio-tkn')
      }
    });
  }

  getProject(projectId) {
    let self = this;
    return $.ajax({
        url: self.api + '/projects/' + projectId,
        type: 'GET',
        contentType: 'application/json',
        headers: {
            'Authorization': localStorage.getItem('hub-iobio-tkn')
        }
    });
  }

  promiseGetGeneSet(projectId, geneSetId) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getGeneSet(projectId, geneSetId)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error getting gene set from Mosaic with gene_set_id " + geneSetId);
        console.log(errorMsg)
        reject("Error getting gene set " + geneSetId + ": " + errorMsg);
      })
    })

  }


  promiseGetVariantSet(projectId, variantSetId, build) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getVariantSet(projectId, variantSetId, build)
      .done(response => {
        let data = response;

        // The gene symbol is in a different field depending on the genome build.
        // Set the 'gene_symbol' field so that we can pull it from one field.
        let geneSymbolField = null
        let impactField = null
        let consequenceField = null
        let afField = null
        if (build === "GRCh38"){
          geneSymbolField  = 'gene_symbol_GRCh38';
          impactField      = 'gene_impact_GRCh38';
          consequenceField = 'gene_consequence_GRCh38';
          afField          = 'gnomad_allele_frequency_GRCh38';
        }
        else if (build === "GRCh37"){
          geneSymbolField = 'gene_symbol_GRCh37';
          impactField      = 'gene_impact_GRCh37';
          consequenceField = 'gene_consequence_GRCh37';
          afField          = 'gnomad_allele_frequency_GRCh37';
        }
        data.variants.forEach(function(variant) {
          if (geneSymbolField &&  variant[geneSymbolField].length > 0 && !variant.hasOwnProperty('gene_symbol')) {
            variant['gene_symbol'] = variant[geneSymbolField][0];
          }
          if (impactField && variant[impactField] && variant[impactField].length > 0) {
            variant['gene_impact'] = variant[impactField][0];
          }
          if (consequenceField && variant[consequenceField] && variant[consequenceField].length > 0) {
            variant['gene_consequence'] = variant[consequenceField][0];
          }
          if (afField && variant.hasOwnProperty(afField) && variant[afField].length > 0) {
            variant['gnomad_allele_frequency'] = variant[afField][0];
          }
          variant['mosaic_id'] = variant.id
        })

        resolve(data)
      })
      .fail(error => {
        self.getVariantSet(projectId, variantSetId, 'old_project')
        .done(response => {
          resolve(response)
        })
        .fail(error => {
          let errorMsg = self.getErrorMessage(error);
          console.log("Error getting variant set " + variantSetId + " from Mosaic. This project may not be up to date with the latest variant annotations.");
          console.log();
          reject("Error getting variant set " + variantSetId + ": " + errorMsg);
        })


      })
    })

  }

  promiseGetVariant(projectId, variant_id, includeAnnotationData=true) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getVariant(projectId, variant_id, includeAnnotationData)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error getting mosaic variant : " + errorMsg + "." )
      })
    })
  }

  promiseLookupVariantByPosition(projectId, variant) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let chr = self.globalApp.utility.stripRefName(variant.chrom)
      self.promiseGetVariantsByPosition(projectId, chr, variant.start, true)
      .then(function(variants) {
        let matching = variants.filter(function(v) {
          if (v.chr == chr &&
              v.r_start == variant.start &&
              v.alt == variant.alt &&
              v.ref == variant.ref) {
            return true;
          } else {
            return false;
          }
        })
        if (matching.length > 0) {
          resolve(matching[0])
        } else {
          reject("Cannot find matching Mosaic variant " + variant.chrom + " " + variant.start )
        }
      })
      .catch(function(error) {
        reject(self.getErrorMessage(error))
      })
    })
  }

  promiseGetVariantsByPosition(projectId, chr, start, includeAnnotationData) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getVariantsByPosition(projectId, chr, start, includeAnnotationData)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error getting mosaic variants by position: " + errorMsg + "." )
      })
    })

  }

  promiseGetAnalysis(projectId, analysisId) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getAnalysis(projectId, analysisId)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error getting analysis: " + errorMsg + "." )
      })
    })

  }
  promiseAddAnalysis(projectId, analysis) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.addAnalysis(projectId, analysis)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error adding analysis: " + errorMsg + "." )
      })
    })

  }

  promiseUpdateAnalysis(analysis) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.updateAnalysis(analysis.project_id, analysis.id, analysis)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error saving analysis: " + errorMsg + "." )
      })
    })

  }

  promiseUpdateAnalysisTitle(analysis) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.updateAnalysisTitle(analysis.project_id, analysis.id, analysis)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        reject("Error updating analysis title " + analysis.id + ": " + errorMsg);
      })
    })

  }


  promiseGetVariantAnnotations(project_id) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getVariantAnnotations(project_id)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error getting variant annotations for project " + project_id)
        console.log(errorMsg)
        reject(errorMsg);
      })
    })

  }



  promiseCreateInterpretationAnnotation(project_id) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.createInterpretationAnnotation(project_id)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error creating variant annotation " + annotationName + " for project " + project_id)
        console.log(errorMsg)
        reject(errorMsg);
      })
    })
  }



  promiseAddVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.addVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error adding variant annotation value " + annotationValue + " for project " + project_id)
        console.log(errorMsg)
        reject(errorMsg);
      })
    })
  }


  promiseDeleteVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.deleteVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error deleting variant annotation value" + annotationValue + " for project " + project_id)
        console.log(errorMsg)
        reject(errorMsg);
      })
    })
  }

  promiseGetSampleHPOTerms(project_id, sample_id) {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getSampleHPOTerms(project_id, sample_id)
      .done(response => {
        resolve(response)
      })
      .fail(error => {
        let errorMsg = self.getErrorMessage(error);
        console.log("Error getting sample HPO terms for project " + project_id + " and sample " + sample_id)
        console.log(errorMsg)
        reject(errorMsg);
      })
    })
  }

  getAnalysis(projectId, analysisId) {
    let self = this;
    return $.ajax({
      url: self.api + '/projects/' + projectId  + '/analyses/' + analysisId,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    })
  }




  addAnalysis(projectId, newAnalysisData) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + projectId + '/analyses/?client_application_id=' + this.client_application_id,
      type: 'POST',
      data: self.stringifyAnalysis(newAnalysisData),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }

  updateAnalysisTitle(projectId, analysisId, newAnalysisData) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + projectId + '/analyses/' + analysisId,
      type: 'PUT',
      data: self.stringifyAnalysis(newAnalysisData),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }


  updateAnalysis(projectId, analysisId, newAnalysisData) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + projectId + '/analyses/' + analysisId
            + '?client_application_id=' + this.client_application_id,
      type: 'PUT',
      data: self.stringifyAnalysis(newAnalysisData),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }

  promiseGetCurrentUser() {
    let self = this;
    return new Promise(function(resolve, reject) {
      self.getCurrentUser()
        .done(response => {
          resolve(response)
        })
        .fail(error => {
          let errorMsg = error.responseText ? error.responseText : "";
          let msg = "Error getting current Mosaic user.  Your authorization may have expired.  Make sure you are still logged into Mosaic, and relaunch the project."
          reject(msg);
        })
    })
  }

  getCurrentUser() {
    let self = this;

    return $.ajax({
      url: self.api + '/user',
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });

  }



  getVariant(project_id, variant_id, includeAnnotationData) {
    let self = this;

    return $.ajax({
          url: self.api + '/projects/' + project_id + '/variants/'+ variant_id + "?include_annotation_data=" + (includeAnnotationData ? 'true' : 'false'),
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });

  }

  getVariantsByPosition(project_id, chr, start, includeAnnotationData) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + project_id + '/variants/position/' + chr + ":" + start + "?include_annotation_data=" + (includeAnnotationData ? 'true' : 'false'),
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });

  }



  getVariantAnnotations(project_id) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + project_id + '/variants/annotations',
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });

  }

  createInterpretationAnnotation(project_id) {
    let self = this;
    let annotationObj = {"name": 'Interpretation',
     "value_type": "string",
     "display_type": "badge",
     "privacy_level": "private",
     "severity": {"Significant": 1, "Uncertain significance": 2, "Not significant": 3, "Not reviewed": 4}};
    return $.ajax({
      url: self.api + '/projects/' + project_id + '/variants/annotations',
      type: 'POST',
      data: JSON.stringify(annotationObj),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }


  addVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue) {
    let self = this;
    let annotationValObj = {"value": annotationValue};
    return $.ajax({
      url: self.api + '/projects/' + project_id + '/variants/' + variant_id + '/annotations/' + annotation_id,
      type: 'POST',
      data: JSON.stringify(annotationValObj),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }

  deleteVariantAnnotationValue(project_id, variant_id, annotation_id, annotationValue) {
    let self = this;
    let annotationValObj = {"value": annotationValue};
    return $.ajax({
      url: self.api + '/projects/' + project_id + '/variants/' + variant_id + '/annotations/' + annotation_id,
      type: 'DELETE',
      data: JSON.stringify(annotationValObj),
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }

  getGeneSet(projectId, geneSetId) {
    let self = this;

    return $.ajax({
      url: self.api + '/projects/' + projectId + '/genes/sets/' + geneSetId,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }


  getVariantSet(projectId, variantSetId, build) {
    let self = this;
    return $.ajax({
      url: self.api + '/projects/' + projectId + '/variants/sets/' + variantSetId + "?include_variant_data=true&include_genotype_data=true",
      data: {
      },
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }



  getSampleHPOTerms(project_id, sample_id) {
    let self = this;

    return $.ajax({
          url: self.api + '/projects/' + project_id + '/samples/'+ sample_id + "/hpo-terms",
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: localStorage.getItem('hub-iobio-tkn'),
      },
    });
  }

  stringifyAnalysis(analysisData) {
    let self = this;
    var cache = [];

    let analysisDataCopy = $.extend({}, analysisData)

    // First get rid of full gene and transcript objects from variants
    // These are too big to stringify and store
    if (analysisDataCopy.payload.hasOwnProperty('variants')) {
      analysisDataCopy.payload.variants.forEach(function(variant) {
        if (variant.gene && self.globalApp.utility.isObject(variant.gene)) {
          variant.gene = variant.gene.gene_name;
        }
        if (variant.transcript && self.globalApp.utility.isObject(variant.transcript)) {
          variant.transcriptId = variant.transcript.transcript_id;
          variant.transcript = null;
        }
        if (variant.variantInspect && variant.variantInspect.geneObject) {
          variant.variantInspect.geneName = variant.variantInspect.geneObject.gene_name
          variant.variantInspect.geneObject = null;
        }
        if (variant.variantInspect && variant.variantInspect.transcriptObject) {
          variant.variantInspect.transcriptId = variant.variantInspect.transcriptObject.transcript_id
          variant.variantInspect.transcriptObject = null;
        }
      })
    }


    let analysisString = JSON.stringify(analysisDataCopy, function(key, value) {
      if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
          }
          // Store value in our collection
          cache.push(value);
      }
      return value;
    });
    cache = [];
    return analysisString;
  }
}
